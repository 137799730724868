import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';
import {ButtonComponent} from '@shared/components/forms/components/button/button.component';

@Component({
  standalone: true,
  selector: 'ups-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
  imports: [
    TranslateModule,
    ButtonComponent
  ]
})

export class ConfirmModalComponent {
  @Input() title: string;
  @Input() message: string = 'Actions.Toast.ConfirmMassage';
  @Input() confirmText = 'Actions.Toast.ConfirmButton';
  @Input() cancelText = 'Actions.Toast.CancelButton';
  @Input() confirmTextStyle = 'danger';

  constructor(public activeModal: NgbActiveModal) {
  }
}
