<div class="modal-header">
  <h4 class="modal-title">{{ title | translate }}</h4>
</div>

<div class="modal-body">
  {{ message | translate }}
</div>


<div class="modal-footer">
  <ups-button
    (click)="activeModal.close(false)"
    [label]="cancelText"
    class="me-1"
    styleType="secondary"
  ></ups-button>
  <ups-button
    (click)="activeModal.close(true)"
    [label]="confirmText"
    [styleType]="confirmTextStyle"
    class=""
  ></ups-button>
</div>
