import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmModalComponent} from '@shared/components/modal/confirm-modal/confirm-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationDialogService {
  constructor(private modalService: NgbModal) {
  }

  openConfirmationDialog(
    title: string,
    message = '',
    confirmText: string,
    confirmTextStyle: string,
    cancelText: string): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
      centered: true,
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.confirmText = confirmText,
      modalRef.componentInstance.cancelText = cancelText;
    modalRef.componentInstance.confirmTextStyle = confirmTextStyle;
    return modalRef.result;
  }
}
